document.addEventListener("vue:load", () => {
  const bottomElement = document.getElementById("bottom-contact-sales")

  if(!bottomElement) return

  const onIntersection = (entries) => {
    for (let entry of entries) {
      if (entry.isIntersecting || entry.intersectionRatio >= 0) {
        let mobileButton = document.getElementById("mobile-cta")
        let rightBox = document.getElementById("right-contact-sales")

        mobileButton.classList.toggle("d-none")
        mobileButton.classList.toggle("d-block")
        rightBox.classList.toggle("fade")
        rightBox.classList.toggle("show")
      }
    }
  }

  const observer = new IntersectionObserver(onIntersection)
  observer.observe(bottomElement)
})
