<template>
  <div class="logo-slider">
    <hooper :settings="settings" ref="carousel" class="h-auto">
      <slide v-for="(logo, index) in logos" :key="index" :index="index" class="">
        <div class="px-3 h-100 mx-auto">
          <img :src="logo.image_url" :alt="logo.alt" class="img-fluid d-block mx-auto" />
        </div>
      </slide>
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide } from "hooper"
import "hooper/dist/hooper.css"

export default {
  props: {
    logos: Array,
  },

  computed: {
    shouldAutoPlay() {
      const onMobile = window.matchMedia(`(max-width: 767px)`).matches

      if(onMobile) return true
      if(this.logos.length > 5) return true

      return false
    },

    settings() {
      return {
        autoPlay: this.shouldAutoPlay,
        playSpeed: 3000,
        infiniteScroll: true,
        wheelControl: false,
        itemsToShow: 3,
        breakpoints: {
          767: {
            itemsToShow: 5
          },
        }
      }
    }
  },

  components: {
    Hooper,
    Slide
  },
}
</script>

<style lang="scss">
  .logo-slider {
    .hooper-track {
      align-items: center !important;
    }

    img {
      max-height: 50px;
    }
  }
</style>
