import imagesLoaded from "imagesloaded"
import Masonry from "masonry-layout"

document.addEventListener("turbolinks:load", () => {
  const container = document.getElementById("gallery")

  if(container) {
    imagesLoaded(container, function(_instance) {
      const elem = document.querySelector(".grid")
      new Masonry(elem, {
        itemSelector: ".grid-item",
        percentPosition: true
      });
    })
  }
})

