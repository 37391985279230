import Dropdown from "bootstrap/js/src/dropdown"

document.addEventListener("vue:load", () => {
  const navbar = document.getElementById("navbar")

  if(!navbar) return

  navbar.addEventListener("shown.bs.collapse", () => {
    const activeElement = navbar.querySelector("a[data-auto-open='true']")
    if(!activeElement) return

    const dropdown = Dropdown.getOrCreateInstance(activeElement)
    dropdown.show()
  })
})

document.addEventListener("vue:load", () => {
  const isMobile = window.matchMedia('screen and (max-width: 576px)')

  const showDropdown = (event) => {
    if(isMobile.matches) return

    const element = event.target.querySelector("a")
    if(element.classList.contains("show")) return

    const dropdown = Dropdown.getOrCreateInstance(element)
    dropdown.show()
  }

  const hideDropdown = (event) => {
    if(isMobile.matches) return

    const element = event.target.querySelector("a")
    const dropdown = Dropdown.getOrCreateInstance(element)

    element.blur()
    dropdown.hide()
  }

  const openFirstItem = (event) => {
    if(isMobile.matches) return
    if(event.target.dataset.bsToggle != "dropdown") return

    event.preventDefault()
    event.stopPropagation()

    const element = event.target.nextElementSibling.querySelector("a")
    window.location = element.href
}



  document.querySelectorAll("#navbar .dropdown").forEach((element) => {
    element.addEventListener("mouseenter", showDropdown, false)
    element.addEventListener("mouseleave", hideDropdown, false)
  })

  document.querySelectorAll("#navbar .nav-link").forEach((element) => {
    element.addEventListener("click", openFirstItem, false)
  })
})

document.addEventListener("vue:load", () => {
  const heroButton = document.getElementById("primary-cta")
  const navButton = document.getElementById("navbar-cta")
  const mobileButton = document.getElementById("mobile-cta")

  if(!navButton) return

  if(!heroButton) {
    navButton.classList.remove("d-none")
    mobileButton.classList.remove("d-none")
    return
  }

  const onIntersection = (entries) => {
    for (let entry of entries) {
      if (entry.intersectionRatio == 0) {
        navButton.classList.remove("d-none")
        mobileButton.classList.remove("d-none")
      } else {
        navButton.classList.add("d-none")
        mobileButton.classList.add("d-none")
      }
    }
  }

  const observer = new IntersectionObserver(onIntersection)
  observer.observe(heroButton)
})

document.addEventListener("vue:load", () => {
  const subNav = document.getElementById("subnav")

  if(!subNav) return

  const nav = subNav.querySelector("nav")
  const onNavIntersection = (entries) => {
    for (let entry of entries) {
      if (entry.intersectionRatio == 1) {
        subNav.classList.remove("stuck")
        nav.classList.add("border-bottom")
      } else {
        subNav.classList.add("stuck")
        nav.classList.remove("border-bottom")
      }
    }
  }

  const observer = new IntersectionObserver(onNavIntersection, {
    rootMargin: "-1px 0px 0px 0px",
    threshold: [1],
  })
  observer.observe(subNav)
})
