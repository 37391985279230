<template>
  <div>
    <div class="row">
      <div class="arrow col-1 justify-content-center align-items-center d-none d-md-flex" @click.prevent="slidePrev">
        <i class="navigation far fa-3x fa-chevron-left"></i>
      </div>
      <div class="col-12 col-md-10 px-0" style="max-height: 485px">
        <hooper :settings="settings" ref="carousel" class="d-flex align-items-center h-100">
          <slide v-for="(video, index) in videos" :key="index" :index="index" class="h-100">
            <div class="px-1 px-md-0 h-100">
              <a :href="video.embed_url" class="h-100" data-fslightbox="true" data-rel="0">
                <div class="position-relative d-flex justify-content-center">
                  <img :src="video.thumbnail_url" alt="" role="presentation" class="rounded-6 img-fluid col-12 col-md-10 h-100" w />
                  <img :src="play_button" alt="" role="presentation" class="position-absolute top-50 start-50 translate-middle" />
                </div>
                 <div class="fs-sm text-muted text-center py-1 fw-semibold">{{ video.description }}</div>
              </a>
            </div>
          </slide>
        </hooper>
      </div>
      <div class="arrow col-1 justify-content-center align-items-center d-none d-md-flex" @click.prevent="slideNext">
        <i class="navigation far fa-3x fa-chevron-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from "hooper"
import "hooper/dist/hooper.css"

export default {
  name: "video-slider",

  props: {
    videos: Array,
    play_button: String,
  },

  data() {
    return {
      settings: {
        infiniteScroll: true,
        wheelControl: false,
        centerMode: true,
        itemsToShow: 1.25,
        breakpoints: {
          577: {
            itemsToShow: 1.75,
          },
          769: {
            itemsToShow: 1.75,
          },
        },
      }
    }
  },

  components: {
    Hooper,
    Slide
  },

  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev()
    },

    slideNext() {
      this.$refs.carousel.slideNext()
    },
  }
}
</script>

<style lang="scss" scoped>
  a {
    text-decoration: none;
  }

  svg.navigation, .arrow {
    color: var(--bs-gray-500);
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: var(--bs-body-color);
    }
  }
</style>
