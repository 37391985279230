// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require.context("../images", true)
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

Rails.start()
Turbolinks.start()

import "../vendor/fontawesome/js/fontawesome"
import "../vendor/fontawesome/js/regular"
import "../vendor/fontawesome/js/light"
import "../vendor/fontawesome/js/brands"

import "../javascript/bootstrap"
import "../javascript/hcaptcha"
import "../javascript/success_stories"
import "../javascript/grid"
import "../javascript/lightbox"
import "../javascript/navbar"
import "../javascript/track"
import "../javascript/storyblok"
import "../javascript/scroll"

import Cookie from "../javascript/cookie";
window.Cookie = Cookie;

import Vue from "vue/dist/vue.esm.js"
import TurbolinksAdapter from "vue-turbolinks"
Vue.use(TurbolinksAdapter)

import Testimonials from "./components/testimonials"
import VideoSlider from "./components/video_slider"
import LogoSlider from "./components/logo_slider"
import Slider from "./components/card_slider"
import OpenJobs from "./components/open_jobs"
import Cookies from "./components/cookies"

document.addEventListener("turbolinks:load", () => {
  const app = document.getElementById("app")
  if(!app) return

  new Vue({
    el: "#app",
    components: { Testimonials, Slider, LogoSlider, OpenJobs, Cookies, VideoSlider },
  })

  const event = new Event("vue:load")
  document.dispatchEvent(event)
})
