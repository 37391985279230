
const scrollToHash = () => {
  if(!window.location.hash.length) return
  const hash = window.location.hash.slice(1)

  const element = document.getElementById(hash)
  if(!element) return

  const yOffset = 80
  const yPos = element.getBoundingClientRect().top + window.scrollY - yOffset
  window.scrollTo({ top: yPos, behavior: "smooth" })
}

document.addEventListener("vue:load", () => {
  scrollToHash()
}, false)

window.addEventListener('hashchange', () => {
  scrollToHash()
}, false)
