<template>
  <div class="mb-4">
    <div v-if="state == 'loading'" class="text-center py-7">
      <a :href="`${baseUrl}kaleido/`" class="btn btn-outline-primary" target="_blank" rel="noopener">Show all jobs</a>
    </div>

    <div v-if="state == 'done'">
      <div class="accordion pb-5" id="jobs">
        <div v-for="(jobs, department, index) in jobsByDepartment" :key="department" class="accordion-item">
          <h2 class="accordion-header" :id="`heading-${parameterize(department)}`">
            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    :data-bs-target="`#collapse-${parameterize(department)}`"
                    :aria-controls="`collapse-${parameterize(department)}`">
              <div class="d-flex align-items-center">
                <h4 class="h5 mb-0">{{ department }}</h4>
                <span class="badge text-white bg-danger ms-2">{{ jobs.length }}</span>
              </div>
            </button>
          </h2>
          <div :id="`collapse-${parameterize(department)}`" class="show accordion-collapse collapse"
              :aria-labelledby="`heading-${parameterize(department)}`">
            <div class="accordion-body my-n2 pt-1">
              <div v-for="(job, jobIndex) in jobs" :key="job.shortcode">
              <div class="d-flex align-items-center justify-content-between py-2" :class="{ 'border-bottom': jobIndex != jobs.length - 1 }">
                <div class="me-4">
                  <a :href="job.url" class="h5 fw-bold text-decoration-none font-heading" target="_blank" rel="noopener">{{ job.title }}</a>
                  <div class="text-muted small mt-1">
                    <span class="d-inline-block me-2" v-if="jobLocation(job).length > 0"><i class="far fa-building pr-1"></i> {{ jobLocation(job) }}</span>
                    <span class="d-inline-block" v-if="job.employment_type.length > 0"><i class="far fa-clock pr-1"></i> {{ job.employment_type }}</span>
                  </div>
                </div>
                <div class="flex-shrink-0">
                  <a :href="job.url" class="btn btn-outline-primary px-5 d-none d-md-block" target="_blank" rel="noopener">Learn more</a>
                  <a :href="job.url" class="d-md-none"  target="_blank" rel="noopener"><i class="far fa-chevron-right"></i></a>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center py-4 mx-auto" style="max-width: 650px;">
      <h5 class="h6">Can't find your job?</h5>
      <p class="mb-1">
        Don't worry, Kaleido is currently growing fast and is looking for experienced talents in all departments.
        <a :href="`${baseUrl}kaleido/j/1FA3C2E9CC/`" target="_blank" rel="noopener">Send us your unsolicited application.</a>
      </p>

    </div>
  </div>
</template>

<script>
const jsonp = require("jsonp")

export default {
  name: "open-jobs",

  data() {
    return {
      baseUrl: "https://apply.workable.com/",
      state: "loading",
      jobs: [],
    }
  },

  computed: {
    jobsByDepartment() {
      const groupKey = "department"

      let groupedJobs =  this.jobs.reduce(
        (result, item) => ({
          ...result,
          [item[groupKey]]: [
            ...(result[item[groupKey]] || []),
            item,
          ],
        }),
        {},
      )

      // Don't display the unsolicited department
      delete groupedJobs["Unsolicited"]

      // Place uncatageorized jobs into a "Other" group
      if(groupedJobs.hasOwnProperty("null")) {
        delete Object.assign(groupedJobs, {["Other"]: groupedJobs["null"] })["null"]
      }

      return Object.fromEntries(Object.entries(groupedJobs).sort( ))
    },
  },

  mounted() {
    this.loadJobs()
  },

  methods: {
    loadJobs() {
      const self = this
      self.state = "loading"

      jsonp(`${this.baseUrl}/api/v1/widget/accounts/381974?origin=embed&callback=whrcallback&details=true`, null, (err, data) => {
        if(!err) {
          self.jobs = data.jobs
          self.state = "done"
        }
      })
    },

    jobLocation(job) {
      return [job.city, job.country].filter(el => el.length > 0).join(", ")
    },

    parameterize(value) {
      return value.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-");
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-header {
    cursor: pointer;

    i {
      color: #2699FB;
      transition-duration: 0.3s;
      transition-property: transform;
      transform: rotate(45deg);
    }

    &.collapsed {
      i {
        transform: rotate(0deg);
      }
    }
  }
</style>
