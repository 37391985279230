<template>
  <div>
    <div class="row">
      <div class="arrow col-1 justify-content-end align-items-center d-none d-md-flex" @click.prevent="slidePrev">
        <i class="navigation far fa-2x fa-chevron-left"></i>
      </div>
      <div class="col-12 col-md-10 px-0">
        <hooper :settings="settings" ref="carousel" class="h-auto">
          <slide v-for="(slide, index) in slides" :key="index" :index="index" class="h-auto">
            <div class="px-1 px-md-3 h-100">
                <div class="card border-0 h-100">
                  <div class="story-tile-image rounded-top-6"
                  :style="{ backgroundImage: 'url(' + slide.image + ')' , backgroundSize: 'cover' }"></div>
                  <div class="card-body bg-light p-4">
                    <span class="text-brand text-uppercase fw-bold d-block mb-1 font-heading">{{ slide.name }}</span>
                    <div v-html="slide.text"></div>
                  </div>
                  <div class="card-footer border-top-0 px-4 rounded-bottom-6">
                    <a :href="slide.url" class="text-decoration-none stretched-link fw-semibold">
                      Learn more <i class="ms-1 far fa-angle-right" data-fa-transform="grow-8 right-3 down-1"></i>
                      </a>
                  </div>
                </div>
            </div>
          </slide>
        </hooper>
      </div>
      <div class="arrow col-1 justify-content-start align-items-center d-none d-md-flex" @click.prevent="slideNext">
        <i class="navigation far fa-2x fa-chevron-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from "hooper"
import "hooper/dist/hooper.css"

export default {
  props: {
    slides: Array,
  },

  data() {
    return {
      settings: {
        infiniteScroll: true,
        wheelControl: false,
        mouseDrag: false,
        centerMode: true,
        itemsToShow: 1.25,
        breakpoints: {
          577: {
            centerMode: false,
            itemsToShow: 2
          },
          769: {
            centerMode: false,
            itemsToShow: 3
          },
        },
      }
    }
  },

  components: {
    Hooper,
    Slide
  },

  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev()
    },

    slideNext() {
      this.$refs.carousel.slideNext()
    },
  }
}
</script>

<style lang="scss" scoped>
  a {
    text-decoration: none;
  }

  svg.navigation, .arrow {
    color: var(--bs-gray-500);
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: var(--bs-body-color);
    }
  }

  ul {
    display: flex;
  }
</style>
