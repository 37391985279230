import { left } from "@popperjs/core"

let injected = false
let loaded = false
let callbacks = []

function loadHcaptcha() {
  return new Promise((resolve, reject) => {
    if(loaded) return resolve()
    callbacks.push(resolve)
    if(injected) return
    injected = true

    window.hcaptchaLoaded = () => {
      loaded = true

      for(let i = 0; i < callbacks.length; i++) {
        callbacks[i]()
      }
    }

    let script = document.createElement('script')
    script.setAttribute('src', 'https://hcaptcha.com/1/api.js?onload=hcaptchaLoaded&render=explicit&recaptchacompat=off')
    script.async = true
    document.head.appendChild(script)
  })
}

function hcaptcha_invisible(config) {
  return new Promise((resolve, reject) => {
    loadHcaptcha().then(() => {
      let node = document.createElement("div")
      document.body.appendChild(node)
      try {
        const widgetID = hcaptcha.render(node, {
          'sitekey': config.sitekey,
          'size': 'invisible',
          'callback': (token) => {
            resolve(token)
            document.body.removeChild(node)
          },
          'error-callback': (error) => {
            reject({message: `Failed to run no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` })
          },
          'close-callback': () => {
            reject({event: 'close', message: `Please verify you are not a bot by completing the puzzle.` })
          }
        })
        hcaptcha.execute(widgetID)
      } catch(error) {
        reject({message: `Failed to render no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` })
      }
    }).catch((error) => {
      reject({message: `Failed to load no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` })
    })
  })
}

function hcaptcha_invisible_submit(form, config) {
  return new Promise((resolve, reject) => {
    if(form.dataset.hcaptchaActive) return
    form.dataset.hcaptchaActive = true

    hcaptcha_invisible(config).then((captcha) => {
      let field = form.querySelector("input[name='hcaptcha_response']")

      field.value = captcha
      form.dataset.hcaptchaActive = false
      form.submit()
      resolve()
    }).catch((error) => {
      if(error.event != "close") alert(error.message)
      form.dataset.hcaptchaActive = false
      reject()
    })
  })
}

function hcaptcha_checkbox(config) {
  return new Promise((resolve, reject) => {
    loadHcaptcha().then(() => {
      try {
        hcaptcha.render(config.element, {
          'sitekey': config.sitekey,
          'callback': (token) => {
            resolve(token)
          },
          'error-callback': (error) => {
            reject({message: `Failed to run no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` })
          }
        })
      } catch(error) {
        reject({message: `Failed to render no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` })
      }
    }).catch((error) => {
      reject({message: `Failed to load no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` })
    })
  })
}

document.addEventListener("vue:load", () => {
  const form = document.querySelector(".hcaptcha-form")
  if(!form) return

  form.addEventListener("submit", (e) => {
    e.preventDefault()
    const submit = form.querySelector(".hcaptcha-submit")
    submit.setAttribute("disabled", true)
    hcaptcha_invisible_submit(form, { sitekey: form.dataset.hcaptchaSitekey }).finally(() => {
      submit.setAttribute("disabled", false)
    })
  })
})

export {
  hcaptcha_invisible,
  hcaptcha_invisible_submit,
  hcaptcha_checkbox,
}
