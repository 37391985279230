<template>
  <div class="text-white bg-text">
    <div class="mx-auto position-relative py-7" style="max-width: 700px">
      <h2 class="text-white text-center mb-5">{{ heading }} </h2>
      <hooper :settings="settings" ref="carousel" @slide="updateCarousel" class="h-auto">
        <slide v-for="(testimonial, index) in testimonials" :key="index" :index="index" class="h-auto">
          <div class="px-1 px-md-0 h-100">
            <div class="card border border-1 rounded-6 h-100 overflow-hidden">
              <div class="card-body bg-text text-center py-4">
                <div :style="companyLogoStyle(testimonial.company_image)" class="mx-auto"></div>
                <p class="lead mt-4"> {{ testimonial.text }}</p>
                <div class="d-flex mt-4 align-items-center">
                  <div class="ms-auto me-3">
                    <div style="max-width: 50px;">
                      <img :src="testimonial.author_image" :alt="testimonial.name" class="img-fluid rounded-circle" />
                    </div>
                  </div>
                  <div class="me-auto text-start fs-sm">
                    <div>
                      <strong>{{ testimonial.name }}</strong>
                    </div>
                    <div>
                      {{ testimonial.company}}, {{ testimonial.role }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </hooper>
      <ul class="nav nav-testimonials justify-content-center mt-3 d-none d-md-flex">
        <li v-for="(testimonial, index) in testimonials" :key="index" :index="index" class="nav-item">
          <a class="link-white" :class="{ active: (carouselData == index) }" @click.prevent="slideTo(index)">
            <i class="inactive-state far fa-circle" data-fa-transform="grow-4"></i>
            <i class="active-state far fa-dot-circle" data-fa-transform="grow-4"></i>
          </a>
        </li>
    </ul>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from "hooper"
import "hooper/dist/hooper.css"

export default {
  props: {
    heading: String,
    testimonials: Array,
  },

  data() {
    return {
      carouselData: 0,
      settings: {
        autoPlay: false,
        playSpeed: 5000,
        infiniteScroll: true,
        wheelControl: false,
        centerMode: true,
        itemsToShow: 1.25,
        breakpoints: {
          577: {
            itemsToShow: 1
          },
        }
      }
    }
  },

  watch: {
    carouselData () {
      this.$refs.carousel.slideTo(this.carouselData);
    }
  },

  methods: {
    updateCarousel(payload) {
      this.carouselData = payload.currentSlide % this.testimonials.length;
    },

    slideTo(index) {
      this.carouselData = index;
    },

    companyLogoStyle(url) {
      return {
        backgroundImage: `url("${url}")`,
        backgroundSize: "contain",
        width: "200px",
        height: "100px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        filter: "grayscale(100%)",
      }
    }
  },

  components: {
    Hooper,
    Slide
  },
}
</script>

<style lang="scss" scoped>

.card.border {
  border-color: #DDD;
}

.nav-testimonials {
  a {
    cursor: pointer;
    font-size: var(--bs-extremely-small-font-size);
    padding: 12px;
  }


  .active-state {
    display: none;
  }

  .active {
    .inactive-state {
      display: none;
    }

    .active-state {
      display: inline;
    }
  }
}
</style>
